import React from 'react';

const Header = () => {
  return (
    <div id="header-box">
      <img id="logo" src="../assets/nemo-logo.png" />
    </div>
  );
};

export default Header;
