import React from 'react';

const Images = () => {
  return (
    <div id="gif-box">
      <img id="gif" src="../assets/overview.gif" />
    </div>
  );
};

export default Images;
